import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { resizeObserverHandler } from './util/resizeObserverHandler.js';
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status';
/**
* `ai-carbon-hero`
* 
* 
* @customElement
* @polymer
*/
class AiCarbonHero extends resizeObserverHandler(PolymerElement) {
    static get is(){ return 'ai-carbon-hero'; }
    static get template() {
    return html`
        <style>
            :host {
                --hue:var(--ai-carbon-hero-primary-hue, var(--ai-secondary-hue, 230));
                --saturation:var(--ai-carbon-hero-primary-saturation, var(--ai-secondary-saturation, 15%));
                --lightness:var(--ai-carbon-hero-primary-lightness, var(--ai-secondary-lightness, 25%));
                --background-hue:var(--ai-carbon-hero-background-hue, var(--ai-background-hue, var(--_bgh,0)));
                --background-lightness:var(--ai-carbon-hero-background-lightness, var(--ai-background-lightness, var(--_bgl, 100%)));
                --background-saturation:var(--ai-carbon-hero-background-saturation, var(--ai-background-saturation, var(--_bgs, 100%)));
                

                position: relative;
                display: block;
                box-sizing: border-box;
                width: 100%;
                font-size:.6em;
                line-height: 1.4;
                background-image:var(--ai-carbon-hero-background);
                background-position: center;
                background-size: cover;
                display:flex;
                flex-direction:column;
                align-items:center;
                justify-content: center;
                padding:6.5em .5em;
                color:#222;
            }
            
            :host([sm]){
                font-size:.6em;
            }
            :host([med]){
                font-size:.8em;
            }
            :host([lrg]){
                font-size:1em;
            }
            
            :host([light]) h1{
                color:#222;
            }

            :host([dark]){
                --_bgh:0;
                --_bgl:0%;
                --_bgs:100%;
            }
            :host([dark]) h1{
                color:#FFF;
            }
            
            div{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, 
                    hsla(var(--background-hue),var(--background-saturation),var(--background-lightness),1) 10%,
                    hsla(var(--background-hue),var(--background-saturation),var(--background-lightness),.75)
                );
            }
            h1{
                line-height: 1.2;
                font-size:1.8em;
                text-align: center;
                margin:0 0 1.1em 0;
                position: relative;
                z-index: 2;
            }
            a{
                display: inline-block;
                font-weight: var(--ai-carbon-hero-button-font-weight, 500);
                font-size: 1.1em;
                padding:1em 4em;
                letter-spacing:1px;
                color:var(--ai-carbon-hero-button-color, #FFF);
                background:hsl(var(--hue), var(--saturation), var(--lightness));
                text-decoration: none;
                position: relative;
                z-index: 2;
                box-shadow: 0px 2px 9px 0px hsla(0, 0%, 0%, .4), 0px 5px 3px -3px hsla(0,0%,0%,0.4);
                box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
                transition:box-shadow .3s;
            }
            a:hover{
                background:hsl(var(--hue), calc(var(--saturation) + 5%), calc(var(--lightness) + 10%));
                
                box-shadow: 0 12px 16px 1px rgba(0, 0, 0, 0.14), 0 4px 22px 3px rgba(0, 0, 0, 0.12), 0 6px 7px -4px rgba(0, 0, 0, 0.4);
            }            
            </style>
            <div></div>
            <h1><slot>YOUR PREMIER</br>SELF STORAGE FACILITY</slot></h1>
            <slot name='button'><a href$='[[href]]'>VIEW UNIT RATES</a></slot>
            `;
        }
        static get properties() {
            return {
                version : {
                    type : String,
                    value : '0.6.0',
                    readonly : true
                },
                prop1 : {
                    type : String,
                    value : 'ai-carbon-hero',
                },
                breakpoints : {
                    type : Object,
                    value : {
                        lrg : 1000,
                        med : 700,
                        sm : 500
                    }
                },
                href : {
                    type : String,
                    value : '#'
                },
                backgroundImage : {
                    type : String,
                    value : null,
                    observer : '_backgroundImageObserver'
                }
            };
        }
    constructor(){
        super();
        this.removeAttribute('unresolved');
    }

    ready(){
        super.ready();
    }

    _loadImg(){
        const img = new Image();
        img.addEventListener('load', evt => {
            this.style.backgroundImage = `url(${this.backgroundImage})`;
        });
        img.src = this.backgroundImage;
    }

    _backgroundImageObserver(newVal, oldVal){
        if(this.backgroundImage){
            afterNextRender(this, this._loadImg);
        }
    }
}

window.customElements.define(AiCarbonHero.is, AiCarbonHero);
